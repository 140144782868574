<template>
  <ion-page>
    <toolbar></toolbar>
    <!-- fab placed to the bottom end -->
    <ion-fab 
      class="fab" 
      vertical="top" 
      horizontal="end" 
      slot="fixed"
      >
      <ion-fab-button @click.prevent="addNewPost">
        <ion-icon :icon="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-content fullscreen > 
      <ion-grid >
        <ion-row class="ion-justify-content-center">
        <ion-col size-xs="12" size-sm="11" size-md="5">
          <div class="card-title">User Profile</div>
          <ion-item>
            <img :src="userStore.backendUser.picture" slot="start"/>
            <ion-label>{{ userStore.backendUser.name }}</ion-label>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="11" size-md="5">
          <!-- <ion-searchbar @search="searchInput" v-model="state.search"></ion-searchbar> -->
          <div class="card-title">My Ads</div>
          <ion-row>
            <ion-col size="12"
              v-for="post in state.posts"
              :key="post.id"
              class="post"
              >
              <ad-item :item="post"></ad-item>
            </ion-col>
          </ion-row>
        </ion-col>
        </ion-row>
      </ion-grid>
      <ion-infinite-scroll 
        threshold="50px" 
        @ionInfinite="loadData"
        position="bottom">
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>      
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent,
         IonGrid, IonRow, IonCol,
         IonInfiniteScroll, IonInfiniteScrollContent,
        //  IonSearchbar,
         IonFab, IonFabButton,
         IonIcon,
         IonItem,
         IonLabel,
         // IonTitle
         // IonButton
       } from '@ionic/vue'
import { defineComponent, reactive, onMounted,
         watch, 
        } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
// import axios from 'axios'
import AdItem from '@/components/AdItem.vue'
// import mainmenu from '@/utils/general'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/utils/api'
import { addOutline } from 'ionicons/icons'
import userStore from '@/store/user'

export default defineComponent({
  name: 'Post',
  components: { 
        IonPage, IonContent,
        IonGrid, IonRow, IonCol,
        Toolbar, AdItem, 
        IonInfiniteScroll, IonInfiniteScrollContent,
        // IonSearchbar,
        IonFab, IonFabButton,
        IonIcon,
        IonItem,
        IonLabel,
        // IonTitle,
        // IonButton,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    let query = route.query
    const addNewURL = `/sg/new/`

    const state = reactive({
        menu: {},
        search: decodeURIComponent(route.query.q || ""),
        posts: [],
        nextpage: ''
    })

    function addNewPost() {
      router.push(addNewURL)
    }

    function fetchNextPage() {
      // console.log(`fetchNextPage: ${state.nextpage}`)
      if (state.nextpage != "null") {
        // const api = `${state.nextpage}`
        // console.log(api)
        console.log(query)
        axios.get(state.nextpage, { params: query })
        .then(res => {
          // replace http to https as a temporary workaround
          state.nextpage = String(res.data.next).replace('http:','https:')
          state.posts = [...state.posts, ...res.data.result]
        })
        .catch(e => {
          console.log(e)
        })
      }
    }

    async function reloadData() {
      state.posts = []
      state.nextpage = `/user/posts`
      await fetchNextPage()
    }

    async function loadData(evt) {
      await fetchNextPage()
      evt.target.complete()
    }

    onMounted(() => {
    //   state.menu = menus.find(x => x.code === route.params.category)
      reloadData()
    })

    function searchInput(evt) {
      const queryInput = encodeURI(evt.target.value) || ""
      query = { ...route.query }
      if (queryInput) {
      //   state.nextpage += `?q=${queryInput}`
        query.q = queryInput
      } else {
        delete query.q
      }
      router.push({
        name: 'userDashboard',
        // params: {
        //   countrycode: route.params.countrycode,
        //   category: route.params.category
        // },
        query: query
        })
      // fetchNextPage()
    }

    // fetch the user information when params change
    watch(
      () => route.query,
      async newQuery => {
        // console.log('newQuery')
        // console.log(newQuery)
        // state.nextpage= `/${route.params.countrycode}/p/${route.params.category}/`
        // console.log('nextpage')
        // console.log(state.nextpage)
        query = newQuery
        state.search = decodeURIComponent(query.q || "")
        // check if navigating out
        if (route.name === 'userDashboard'){
          await reloadData()
        }
      }
    )

    return { state, route, fetchNextPage, loadData, 
             searchInput, addOutline, addNewURL,
             addNewPost, userStore
            }
  }
})

</script>

<style scoped>
ion-searchbar {
  --background: whitesmoke !important;
  --border-radius: 10px !important;
  --color: var(--ion-color-primary-text-dark) !important;
  --icon-color: var(--ion-color-primary-text-dark) !important;
  --box-shadow: 0px 0px 0px #aaaaaa !important;
}

.fab {
  margin-top: 50px !important;
  margin-right: 10px;
}

ion-fab-button {
  --background: var(--ion-color-primary-shade);
}
</style>